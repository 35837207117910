import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

import API from "../api";
import { toast } from "react-hot-toast";

const getTag = (entity) => {
  if (entity?.startsWith("B-")) return ["B", entity.substring(2)];
  if (entity?.startsWith("I-")) return ["I", entity.substring(2)];

  return ["O", entity];
};

const mergePolygons = (polygons) => {
  let [minX, minY] = [Infinity, Infinity];
  let [maxX, maxY] = [-Infinity, -Infinity];

  for (const polygon of polygons) {
    let [polygonMinX, polygonMinY] = [Infinity, Infinity];
    let [polygonMaxX, polygonMaxY] = [-Infinity, -Infinity];

    for (const point of polygon) {
      [polygonMinX, polygonMinY] = [Math.min(polygonMinX, point.X), Math.min(polygonMinY, point.Y)];
      [polygonMaxX, polygonMaxY] = [Math.max(polygonMaxX, point.X), Math.max(polygonMaxY, point.Y)];
    }

    [minX, minY] = [Math.min(minX, polygonMinX), Math.min(minY, polygonMinY)];
    [maxX, maxY] = [Math.max(maxX, polygonMaxX), Math.max(maxY, polygonMaxY)];
  }

  return [
    { X: minX, Y: minY },
    { X: maxX, Y: minY },
    { X: maxX, Y: maxY },
    { X: minX, Y: maxY },
  ];
};

const categoriesCity = [
  { name: "Date", bg: "bg-blue-500", text: "text-blue-500", border: "border-blue-500", key: "A" },
  { name: "ExamName", bg: "bg-red-500", text: "text-red-500", border: "border-red-500", key: "S" },
  { name: "ExamType", bg: "bg-green-500", text: "text-green-500", border: "border-green-500", key: "D" },
  { name: "PrescType", bg: "bg-yellow-500", text: "text-yellow-500", border: "border-yellow-500", key: "F" },
  { name: "RPPS", bg: "bg-purple-500", text: "text-purple-500", border: "border-purple-500", key: "G" },
  { name: "Secu", bg: "bg-pink-500", text: "text-pink-500", border: "border-pink-500", key: "Q" },
  { name: "Info", bg: "bg-cyan-500", text: "text-cyan-500", border: "border-cyan-500", key: "W" },
  { name: "QSP", bg: "bg-lime-500", text: "text-lime-500", border: "border-lime-500", key: "E" },
  { name: "Frequency", bg: "bg-teal-500", text: "text-teal-500", border: "border-teal-500", key: "R" },
  { name: "Schedule", bg: "bg-sky-500", text: "text-sky-500", border: "border-sky-500", key: "T" },
];

const categoriesClinic = [
  { name: "PatientFirstname", bg: "bg-red-500", text: "text-red-500", border: "border-red-500", key: "A" },
  { name: "PatientBirthFirstname", bg: "bg-orange-500", text: "text-orange-500", border: "border-orange-500", key: "S" },
  { name: "PatientLastname", bg: "bg-yellow-500", text: "text-yellow-500", border: "border-yellow-500", key: "D" },
  { name: "PatientBirthLastname", bg: "bg-green-500", text: "text-green-500", border: "border-green-500", key: "F" },
  { name: "PatientBirthdate", bg: "bg-teal-500", text: "text-teal-500", border: "border-teal-500", key: "G" },
  { name: "PatientSexe", bg: "bg-blue-500", text: "text-blue-500", border: "border-blue-500", key: "Q" },
  { name: "PatientAdress", bg: "bg-indigo-500", text: "text-indigo-500", border: "border-indigo-500", key: "W" },
  { name: "SSN", bg: "bg-purple-500", text: "text-purple-500", border: "border-purple-500", key: "E" },
  { name: "EstablishmentName", bg: "bg-pink-500", text: "text-pink-500", border: "border-pink-500", key: "R" },
  { name: "PrescriberFirstname", bg: "bg-red-600", text: "text-red-600", border: "border-red-600", key: "T" },
  { name: "PrescriberLastname", bg: "bg-orange-600", text: "text-orange-600", border: "border-orange-600", key: "J" },
  { name: "PrescriberRPPS", bg: "bg-yellow-600", text: "text-yellow-600", border: "border-yellow-600", key: "K" },
  { name: "Sampler", bg: "bg-green-600", text: "text-green-600", border: "border-green-600", key: "L" },
  { name: "SamplingDate", bg: "bg-teal-600", text: "text-teal-600", border: "border-teal-600", key: "U" },
  { name: "SamplingTime", bg: "bg-blue-600", text: "text-blue-600", border: "border-blue-600", key: "I" },
  { name: "ExamName", bg: "bg-indigo-600", text: "text-indigo-600", border: "border-indigo-600", key: "O" },
  { name: "Service", bg: "bg-purple-600", text: "text-purple-600", border: "border-purple-600", key: "P" },
  { name: "Room", bg: "bg-pink-600", text: "text-pink-600", border: "border-pink-600", key: "Y" },
  { name: "IPP", bg: "bg-red-700", text: "text-red-700", border: "border-red-700", key: "1" },
  { name: "AdmissionNumber", bg: "bg-orange-700", text: "text-orange-700", border: "border-orange-700", key: "2" },
  { name: "AdmissionDate", bg: "bg-yellow-700", text: "text-yellow-700", border: "border-yellow-700", key: "3" },
  { name: "Info", bg: "bg-green-700", text: "text-green-700", border: "border-green-700", key: "4" },
  { name: "Treatments", bg: "bg-teal-700", text: "text-teal-700", border: "border-teal-700", key: "5" },
  { name: "Date", bg: "bg-blue-700", text: "text-blue-700", border: "border-blue-700", key: "6" },
  { name: "Weight", bg: "bg-indigo-700", text: "text-indigo-700", border: "border-indigo-700", key: "7" },
  { name: "Height", bg: "bg-purple-700", text: "text-purple-700", border: "border-purple-700", key: "8" },
  { name: "RecordNumber", bg: "bg-pink-700", text: "text-pink-700", border: "border-pink-700", key: "9" },
  { name: "PrescriptionNumber", bg: "bg-red-800", text: "text-red-800", border: "border-red-800", key: "0" },
];

const Annotation = () => {
  const [doc, setDoc] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const imageRef = useRef(null);
  const [selectionStart, setSelectionStart] = useState(null);
  const [selectionEnd, setSelectionEnd] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [categorySearch, setCategorySearch] = useState("");
  const [indexesSelected, setIndexesSelected] = useState([]);
  const searchModalRef = useRef(null);
  const [selectedIndexSearchCategory, setSelectedIndexSearchCategory] = useState(0);
  const [searchModal, setSearchModal] = useState({ visible: false, x: 0, y: 0 });

  const categories = useMemo(() => {
    if (doc?.type === "clinic") return categoriesClinic;
    return categoriesCity;
  }, [doc]);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  const ner = useMemo(() => {
    let data = { Date: { items: [] }, ExamName: { items: [] }, RPPS: { items: [] } };

    if (!doc?.labels || !doc?.tokens) return {};

    for (let i = 0; i < doc.labels.length; i++) {
      const label = doc.labels[i];
      const word = doc.tokens[i];

      const [bi, tag] = getTag(label);

      if (tag === "O") continue;

      if (!data[tag]) data[tag] = { items: [] };

      const [_, oldTag] = getTag(doc.labels[i - 1]);
      if (bi === "I" && oldTag === tag) {
        data[tag].items[data[tag].items.length - 1].contents.push(word);
        data[tag].items[data[tag].items.length - 1].indexes.push(i);
        continue;
      }

      data[tag].items.push({ contents: [word], indexes: [i] });
    }

    Object.keys(data).forEach((key) => {
      data[key].text = categories.find((c) => c.name === key)?.text || "text-gray-500";
      data[key].border = categories.find((c) => c.name === key)?.border || "border-gray-500";
    });

    return data;
  }, [doc]);

  const fetchDoc = async (data = doc) => {
    if (data) saveDoc(JSON.parse(JSON.stringify(data)));

    try {
      const response = await API.get(`/annotation?q=${search}&offset=${offset}&size=1`);
      setDoc(response.data.docs[0]);
      setTotal(response.data.total);
    } catch (error) {
      console.log(error);
      toast.error("Erreur lors de la récupération des données");
    }
  };

  const saveDoc = async (doc) => {
    try {
      const response = await API.put(`/annotation/${doc._id}`, doc);

      if (!response?.ok) throw new Error("Erreur lors de la sauvegarde du document");
      toast.success("Document sauvegardé avec succès");
    } catch (error) {
      console.log(error);
      toast.error("Erreur lors de la sauvegarde du document");
    }
  };

  const deleteDoc = async (doc) => {
    const confirmed = confirm("Voulez-vous vraiment supprimer ce document ?");
    if (!confirmed) return;

    try {
      const response = await API.delete(`/annotation/${doc._id}`);

      if (!response?.ok) throw new Error("Erreur lors de la suppression du document");

      toast.success("Document supprimé avec succès");

      return fetchDoc(null);
    } catch (error) {
      console.log(error);
      toast.error("Erreur lors de la suppression du document");
    }
  };

  const deleteEntity = (index) => {
    const newTags = [...doc.labels];
    newTags[index] = "O";

    const [nextBi, nextTag] = getTag(doc.labels[index + 1]);
    if (nextBi === "I") newTags[index + 1] = `B-${nextTag}`;

    setDoc((prev) => ({ ...prev, labels: newTags }));
  };

  const getActInfo = async (act) => {
    try {
      const index = doc.acts.findIndex((el) => el._id === act._id);
      const newActs = [...doc.acts];

      if (newActs[index].displayInfo) {
        newActs[index].displayInfo = null;
        setDoc((prev) => ({ ...prev, acts: newActs }));
        return;
      }

      let codes = act.codes.join(",");
      if (!codes) codes = act.codesPredicted.join(",");

      const response = await API.get(`/table?indexName=${doc.indexName}&codes=${codes}`);

      newActs[index].displayInfo = response.data;
      setDoc((prev) => ({ ...prev, acts: newActs }));
    } catch (error) {
      console.log(error);
      toast.error("Erreur lors de la récupération des informations de l'act");
    }
  };

  const handleDrop = useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    if (!file) return;

    try {
      const base64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

      const response = await API.post("/annotation", {
        base64,
        fileType: file.type,
      });

      if (!response.ok || !response.data?._id) throw new Error("Failed to upload file");

      window.location.href = `/annotation?q=${response.data._id}`;
    } catch (error) {
      console.error(error);
      toast.error("Error uploading file");
    }
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  useEffect(() => {
    window.addEventListener("drop", handleDrop);
    window.addEventListener("dragover", handleDragOver);

    return () => {
      window.removeEventListener("drop", handleDrop);
      window.removeEventListener("dragover", handleDragOver);
    };
  }, [handleDrop, handleDragOver]);

  useEffect(() => {
    const handleKeyDown = async (event) => {
      if (event.key === "Escape") return setSelectedCategory(null);

      if (event.key === "k") {
        if (total > offset + 1) setOffset((prev) => prev + 1);
        return;
      }

      if (event.key === "j") {
        if (offset > 0) setOffset((prev) => prev - 1);
        return;
      }

      if (event.key === "D") return deleteAnnotation();

      const category = categories.find((c) => c.key === event.key.toUpperCase());
      if (category) setSelectedCategory(category);
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [offset, total]);

  useEffect(() => {
    if (!doc || !doc.polygons || !doc._loaded) return;

    const imageElement = document.getElementById("displayImage");
    if (!imageElement) return;

    let polygons = [];
    for (let i = 0; i < doc.labels.length; i++) {
      const tag = doc.labels[i];
      const polygon = doc.polygons[i];
      if (!polygon) continue;

      if (tag.startsWith("I-") && polygons[polygons.length - 1]?.tag.startsWith("B-") && polygons[polygons.length - 1]?.tag.slice(2) === tag.slice(2)) {
        polygons[polygons.length - 1].index.push(i);
        const pointsAdjusted = polygon.map((point) => ({ X: point.X * imageElement.clientWidth, Y: point.Y * imageElement.clientHeight }));
        const mergedPolygon = mergePolygons([...polygons[polygons.length - 1].bboxes, pointsAdjusted]);
        const pointsString = mergedPolygon.map((point) => `${point.X},${point.Y}`).join(" ");

        polygons[polygons.length - 1].points = pointsString;
        polygons[polygons.length - 1].bboxes.push(pointsAdjusted);
        continue;
      }

      const pointsAdjusted = polygon.map((point) => ({ X: point.X * imageElement.clientWidth, Y: point.Y * imageElement.clientHeight }));
      const pointsString = pointsAdjusted.map((point) => `${point.X},${point.Y}`).join(" ");

      let className = "text-transparent";
      if (tag !== "O") {
        const category = categories.find((el) => el.name === tag.replace(/^(B-|I-)/, "")) || "text-gray-500";
        className = category.text;
      }

      polygons.push({
        tag,
        className,
        index: [i],
        bboxes: [pointsAdjusted],
        points: pointsString,
      });
    }

    setPolygons(polygons);
  }, [doc]);

  useEffect(() => {
    fetchDoc();
  }, [offset]);

  useEffect(() => {
    const timeout = setTimeout(() => fetchDoc(), 2000);

    return () => clearTimeout(timeout);
  }, [search]);

  useEffect(() => {
    let query = new URLSearchParams(window.location.search);
    if (query.get("q")) setSearch(query.get("q"));

    const handleClickOutside = (event) => {
      if (searchModalRef.current && !searchModalRef.current.contains(event.target)) {
        setSearchModal({ visible: false, x: 0, y: 0 });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMouseDown = (event) => {
    event.preventDefault();

    const rect = imageRef.current.getBoundingClientRect();
    setSelectionStart({ x: event.clientX - rect.left, y: event.clientY - rect.top });
    setIsSelecting(true);
  };

  const handleMouseMove = (event) => {
    if (!isSelecting) return;

    const rect = imageRef.current.getBoundingClientRect();
    setSelectionEnd({ x: event.clientX - rect.left, y: event.clientY - rect.top });
  };

  const handleMouseUp = (event) => {
    if (!isSelecting) return;

    setIsSelecting(false);

    if (selectionStart && selectionEnd && (Math.abs(selectionStart.x - selectionEnd.x) > 5 || Math.abs(selectionStart.y - selectionEnd.y) > 5)) selectPolygonsInZone(event);
    else handlePolygonClick(event);

    setSelectionStart(null);
    setSelectionEnd(null);
  };

  const selectPolygonsInZone = (event) => {
    if (!selectionStart || !selectionEnd) return;

    const XMin_selection = Math.min(selectionStart.x, selectionEnd.x);
    const YMin_selection = Math.min(selectionStart.y, selectionEnd.y);
    const XMax_selection = Math.max(selectionStart.x, selectionEnd.x);
    const YMax_selection = Math.max(selectionStart.y, selectionEnd.y);

    let indexesSelected = [];
    for (const polygon of polygons) {
      for (let i = 0; i < polygon.bboxes.length; i++) {
        const [XMin, YMin, XMax, YMax] = [polygon.bboxes[i][0].X, polygon.bboxes[i][0].Y, polygon.bboxes[i][2].X, polygon.bboxes[i][2].Y];

        if (XMax_selection > XMin && XMin_selection < XMax && YMax_selection > YMin && YMin_selection < YMax) indexesSelected.push(polygon.index[i]);
      }
    }

    if (event.metaKey) return toast(indexesSelected.join(", "), { icon: "ℹ️", duration: 10000, id: "info" });

    updateTags(indexesSelected, false);
  };

  const handlePolygonClick = (event) => {
    const rect = imageRef.current.getBoundingClientRect();
    const X = event.clientX - rect.left;
    const Y = event.clientY - rect.top;

    let newIndexesSelected = [];
    for (const polygon of polygons) {
      for (let i = 0; i < polygon.bboxes.length; i++) {
        const [XMin, YMin, XMax, YMax] = [polygon.bboxes[i][0].X, polygon.bboxes[i][0].Y, polygon.bboxes[i][2].X, polygon.bboxes[i][2].Y];

        if (XMin < X && X < XMax && YMin < Y && Y < YMax) newIndexesSelected.push(polygon.index[i]);
      }
    }

    if (event.metaKey) return toast(newIndexesSelected.join(", "), { icon: "ℹ️", duration: 10000, id: "info" });

    setIndexesSelected(newIndexesSelected);

    if (event.altKey) {
      setSearchModal({ visible: true, x: event.clientX, y: event.clientY });
      setCategorySearch("");
      setSelectedIndexSearchCategory(0);
      return;
    }

    const group = event.shiftKey;
    updateTags(newIndexesSelected, group);
  };

  const handleSearchModalClick = (category) => {
    setSelectedCategory(category);
    setSearchModal({ visible: false, x: 0, y: 0 });
    updateTags(indexesSelected, false, category);
  };

  const updateTags = (indexesSelected, group, specificCategory = null) => {
    const newTags = [...doc.labels];
    for (let i = 0; i < indexesSelected.length; i++) {
      const index = indexesSelected[i];

      let category = `O`;
      const prevTag = newTags.find((tag, i) => i < index && getTag(tag)[1] !== "O");
      const [_, prevEntity] = getTag(prevTag);

      const categoryToApply = specificCategory || selectedCategory;

      if (categoryToApply) category = `B-${categoryToApply.name}`;
      if (categoryToApply && i > 0 && indexesSelected[i - 1] === index - 1) category = `I-${categoryToApply.name}`;
      if (categoryToApply && group && prevEntity === categoryToApply.name) category = `I-${categoryToApply.name}`;
      if (indexesSelected.some((index) => getTag(doc.labels[index])[1] === categoryToApply?.name)) category = `O`;

      newTags[index] = category;

      if (newTags[index + 1] && newTags[index + 1].startsWith("I-")) {
        newTags[index + 1] = `B-${newTags[index + 1].substring(2)}`;
      }
    }

    setDoc((prev) => ({ ...prev, labels: newTags }));
  };

  return (
    <div className="px-4 mb-16">
      <div className="flex gap-4 items-center">
        <input className="w-full p-2 rounded-md" type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Rechercher" />
        <button type="button" className="bg-red-500 text-white rounded-md cursor-pointer px-2 py-1 hover:bg-red-600" onClick={() => deleteDoc(doc)}>
          Supprimer
        </button>
        <button type="button" className="bg-green-500 text-white rounded-md cursor-pointer px-2 py-1 hover:bg-green-600" onClick={() => saveDoc(selectedAnnotation)}>
          Sauvegarder
        </button>
      </div>
      <div
        className="grid grid-cols-4 mt-4 w-[calc(100vw-2rem)]"
        onContextMenu={(e) => {
          e.preventDefault();
          if (total > offset + 1) setOffset((prev) => prev + 1);
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <div className="flex flex-col gap-4 justify-center">
          {doc?.acts
            ?.filter((act) => (act.isAdded === true || act.isDeleted === true) && (act.codes.length > 0 || act.codesPredicted.length > 0))
            .map((act) => (
              <div key={act.id} className="border rounded-md p-2  cursor-pointer" onClick={async () => getActInfo(act)}>
                <div className="flex justify-between items-center">
                  <div>
                    {act.codes.join(", ") || act.codesPredicted.join(", ")} - {act.text}
                  </div>
                  <div>
                    {act.isDeleted && "❌"}
                    {act.isAdded && "✅"}
                  </div>
                </div>
                <div className="text-sm text-gray-500">
                  {act.displayInfo?.length === 0 && "No info"}
                  {act.displayInfo?.map((info, index) => (
                    <div key={index} className="mb-2">
                      {info?.exams?.map((exam, index) => (
                        <div key={index}>{exam.value}</div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
        {doc && (
          <div className="col-span-2 flex justify-center" onContextMenu={async (e) => e.stopPropagation()}>
            <div className="relative" onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
              <img ref={imageRef} id="displayImage" src={doc.imageS3Url} className="object-contain max-h-screen" onLoad={() => setDoc((prev) => ({ ...prev, _loaded: true }))} />
              <svg className="absolute top-0 left-0 w-full h-full pointer-events-none">
                {polygons.map((polygon, index) => (
                  <polygon key={index} points={polygon.points} className={`${polygon.className} fill-current opacity-25`} style={{ pointerEvents: "auto" }} />
                ))}
                {isSelecting && selectionStart && selectionEnd && (
                  <rect
                    x={Math.min(selectionStart.x, selectionEnd.x)}
                    y={Math.min(selectionStart.y, selectionEnd.y)}
                    width={Math.abs(selectionEnd.x - selectionStart.x)}
                    height={Math.abs(selectionEnd.y - selectionStart.y)}
                    fill="rgba(0, 0, 255, 0.2)"
                    stroke="blue"
                    strokeWidth="1"
                  />
                )}
              </svg>
            </div>
          </div>
        )}
        <div className="h-screen overflow-y-auto">
          {Object.keys(ner).map((key) => (
            <div key={key} className={`flex flex-col gap-2 mb-4 border-2 rounded-md p-2 ${ner[key].border} ${ner[key].text}`}>
              <div className="font-bold flex justify-between items-center gap-2">
                <div>{key}</div>
                <div>{ner[key].items.length === 0 && "⚠️"}</div>
              </div>
              {ner[key].items.map((entity, index) => (
                <div key={index} className="relative">
                  <div className="flex gap-2">
                    {entity.contents.map((content, index) => (
                      <span key={index} className="relative group cursor-pointer" onClick={() => deleteEntity(entity.indexes[index])}>
                        {content}
                        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden group-hover:inline-block">❌️</span>
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="fixed bottom-4 left-4 w-[calc(100vw-2rem)] z-10">
        <div className="flex justify-between">
          <div className="flex gap-2 overflow-x-auto max-w-full" style={{ scrollbarWidth: "none" }}>
            {categories.map((category) => (
              <div
                key={category.key}
                className={`flex-shrink-0 flex gap-1 items-center justify-center px-1 py-0.5 rounded cursor-pointer text-xs ${category.bg} text-white ${
                  selectedCategory?.key === category.key && "border border-black"
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                <div>{category.key} -</div>
                <div>{category.name}</div>
              </div>
            ))}
          </div>
          <div className="flex items-center">
            <input
              type="number"
              value={offset + 1}
              min={1}
              max={total}
              className="w-16"
              onChange={(e) => {
                const newOffset = Math.max(0, Math.min(parseInt(e.target.value, 10) - 1, total - 1));
                setOffset(newOffset);
              }}
            />
            <span className="text-gray-500 w-max">/ {total}</span>
          </div>
        </div>
      </div>
      {searchModal.visible && (
        <div ref={searchModalRef} className="fixed bg-white border rounded-md shadow-md p-2 z-20" style={{ top: `${searchModal.y}px`, left: `${searchModal.x}px` }}>
          <input
            type="text"
            value={categorySearch}
            onChange={(e) => {
              setCategorySearch(e.target.value);
              setSelectedIndexSearchCategory(0);
            }}
            onKeyDown={(event) => {
              const filteredCategories = categories.filter((category) => category.name.toLowerCase().includes(categorySearch.toLowerCase()));

              switch (event.key) {
                case "Enter":
                  if (filteredCategories.length > 0) {
                    handleSearchModalClick(filteredCategories[selectedIndexSearchCategory]);
                  }
                  break;
                case "ArrowUp":
                  event.preventDefault();
                  setSelectedIndexSearchCategory((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : filteredCategories.length - 1));
                  break;
                case "ArrowDown":
                  event.preventDefault();
                  setSelectedIndexSearchCategory((prevIndex) => (prevIndex < filteredCategories.length - 1 ? prevIndex + 1 : 0));
                  break;
              }
            }}
            placeholder="Search category"
            className="w-full p-1 mb-2 border rounded"
            autoFocus
          />
          <div className="max-h-40 overflow-y-auto">
            {categories
              .filter((category) => category.name.toLowerCase().includes(categorySearch.toLowerCase()))
              .map((category, index) => (
                <div
                  key={category.key}
                  className={`cursor-pointer px-2 py-1 hover:bg-gray-100 rounded-md ${category.text} ${index === selectedIndexSearchCategory ? "bg-gray-200" : ""}`}
                  onClick={() => handleSearchModalClick(category)}
                >
                  {category.name}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Annotation;
